<template>
  	<div class="content-wrapper">
		<ErrorModal
			:modalShow="modalError.showModal"
			:typeModal="modalError.typeModal"
			:caseModal="modalError.caseModal"
			@confirmed="redirectToList"
		/>
    	<PageHeader :screenName="empresa.nome" :linkItems="linkItems" />
		<main class="card hidden_overflow_tabela">
			<HeaderEmpresa :empresa="empresa" @confirmed="resolveRoute" class="shadow header"/>
			<div>
        <section class="statusTermo card mb-0 pt-2 px-2">
          <alertaTodasAbas :situacao="situacaoCadastro" :show="situacaoCadastro == 'Incompleto'" />

          <p class="termo mb-1">
						Os dados pessoais aqui cadastrados serão utilizados pelo SESI
						conforme estabelecido na
						<a
							href="https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm"
							target="_blank"
						><strong>lei n.º 13.709 do Marco Civil da Internet</strong> </a
						>, com a finalidade de organização de campanha de vacinação e
						repasse de informações para o Ministério da Saúde.
					</p>
					<p class="termo mb-1" v-if="termsInfo.message" v-html="termsInfo.message"/>
        </section>

				<section class="filters pb-1" v-if="!loadingData">
          
          <filters @actionSearch="handleSubmitFormFilters" @actionClear="clearFilters">
            <validation-observer ref="formFilters">
              <b-form>
                <b-row class="align-items-center px-2 pt-1">
                  <b-col sm="6" lg="4">
                    <b-form-group
                      label="Nome do colaborador"
                      label-for="name"
                      label-class="font_size_label"
                    >
                      <validation-provider #default="{ errors }" name="Nome">
                        <b-form-input
                          id="name"
                          v-model="search.nome"
                          placeholder="Nome do colaborador"
                          autocomplete="off"
                          maxLength="255"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" lg="4">
                    <b-form-group
                      label="CPF"
                      label-for="cpf"
                      label-class="font_size_label"
                    >
                      <validation-provider #default="{ errors }" name="cpf">
                        <b-form-input
                          id="cpf"
                          v-model="search.cpf"
                          placeholder="000.000.000-00"
                          v-mask="'###.###.###-##'"
                          maxLength="14"
                          autocomplete="off"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" lg="4" >
                    <b-form-group
                      label="Categoria"
                      label-for="active"
                      label-class="font_size_label"
                    >
                      <v-select
                        id="active"
                        v-model="search.categoria"
                        :options="categoriaSelect"
                        variant="custom"
                        item-text="descricao"
                        item-value="id_categoria"
                        placeholder="Selecione a categoria"
                        label="descricao"
                      >
                        <template v-slot:no-options>Nenhum registro encontrado.</template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col  sm="6" lg="4">
                    <b-form-group
                      label="Matrícula"
                      label-for="matricula"
                      label-class="font_size_label"
                    >
                      <validation-provider  #default="{ errors }" name="matricula">
                        <b-form-input
                          id="matricula"
                          v-model="search.matricula"
                          placeholder="Matrícula da empresa"
                          autocomplete="off"
                          maxLength="100"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col sm="6" lg="4"  >
                    <b-form-group
                      label="Setor"
                      label-for="setor"
                      label-class="font_size_label"
                    >
                      <validation-provider  #default="{ errors }" name="setor">
                        <b-form-input
                          id="setor"
                          v-model="search.setor"
                          placeholder="Área, setor ou departamento"
                          autocomplete="off"
                          maxLength="100"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </filters>
				</section>	
        <div v-if="loadingData" class="d-flex justify-content-center my-3">
          <div class="spinner-border text-custom-blue">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
				<section class="list-colaborador" v-if="!loadingData">
					<b-table
						id="tableColaborador"
						responsive
						:stickyColumn="true"
						:busy.sync="filtering"
						:fields="table.fields"
						:items="table.items"
						@sort-changed="ordenarColuna"
						no-local-sorting
						:no-sort-reset="true"
					>
						<template #cell(nome)="row">
							<span class="font-weight-bold comprimento_maximo_celulas" v-b-tooltip>
								{{ row.item.nome }}
							</span>
						</template>
            <template #cell(cpf)="row">
							<span class="font-weight-bold comprimento_maximo_celulas">
								{{ row.item.cpf | VMask('###.###.###-##') }}
							</span>
						</template>
						<template #cell(matricula)="row">
							<span v-text="trataDescricaoMatriculaSetor(row.item.matricula)"></span>
						</template>
						<template #cell(setor)="row">
							<span v-text="trataDescricaoMatriculaSetor(row.item.setor)"></span>
						</template>
						<template #cell(id_categoria)="row">
							<span class="font-weight-bold comprimento_maximo_celulas" v-b-tooltip>
								{{ row.item.descricao }}
							</span>
						</template>
						<template #cell(actions)="row">
							<feather-icon
								@click.prevent="redirectToShow(row.item)"
								id="view-colaborador"
								role="button"
								class="text-custom-blue mr-1"
								icon="EyeIcon"
								size="18"
							/>
							<feather-icon
								v-if="canManageColab"
								@click.prevent="redirectToEdit(row.item)"
								id="edit-colaborador"
								role="button"
								class="text-custom-blue mr-1"
								icon="EditIcon"
								size="18"
							/>
						</template>
					</b-table>
					<div class="pb-2 px-1">
						<span v-if="withoutColab" class="ml-1">Nenhum colaborador encontrado</span>
						<b-col sm="12" class="mt-2">
							<CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
						</b-col>
					</div>
				</section>
			</div>
		</main>
  	</div>
</template>

<script>
import { actions, subjects } from "@/libs/acl/rules";
import {
  BForm,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  VBTooltip,
  BSpinner,
  BCard,
  BLink
} from "bootstrap-vue";
import vSelect from "vue-select";
import moment from "moment";
import CustomPagination from "@/views/components/custom/pagination/CustomPagination.vue";
import PageHeader from "@/views/components/custom/page-header/PageHeader.vue";
import TableSwitch from "@/views/components/custom/table-switch/TableSwitch.vue";
import TimedAlert from "@/views/components/custom/timed-alert/TimedAlert.vue";
import HeaderEmpresa from "@/views/components/custom/page-header/Header-empresa.vue";
import TableFilter from "@/views/components/custom/table-filter-section/TableFilter.vue";
import ErrorModal from "@/views/components/custom/modals/ErroIdInexistenteModal.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { getIdEmpresa } from "@core/utils/store/getStore";
import { setIdEmpresa } from "@core/utils/store/setStore";
import alertaTodasAbas from '@/views/components/custom/timed-alert/alertaAbasIncompletasFechavel.vue'
import Filters from '@/views/components/custom/filters/Filters.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import VerificaAceiteTermoEmpresa from './Services/VerificaAceiteTermoEmpresa';

export default {
  	title: "Colaboradores",
	components: {
		BForm, BCol, BRow, BFormGroup, BFormInput,
		BButton, BTable, TableSwitch, BCard, vSelect,
		CustomPagination, PageHeader, TimedAlert,
		VuePerfectScrollbar, BSpinner, HeaderEmpresa,
		ErrorModal, alertaTodasAbas, TableFilter,
		ValidationObserver, ValidationProvider, BLink,
    Filters
	},

  directives: {
    "b-tooltip": VBTooltip,
  },

	data() {
		return {
			linkItems: [
				{
					name: "Empresa",
					routeName: "empresa-colaborador-list",
				},
				{
					name: "Ver empresa",
					active: true,
				},
				{
					name: "Carregando dados...",
					active: true,
				},
			],
			alert: {
				errorMessage: "",
				show: false,
			},
			modalError: {
				showModal: false,
				typeModal: "error",
				caseModal: "idInexistente",
			},
			settings: {
				maxScrollbarLength: 70,
				wheelSpeed: 0.2,
				wheelPropagation: false,
			},
			termsInfo: {
				message: "",
			},
			search: {
				nome: null,
				cpf: null,
				categoria: null,
				matricula: null,
				setor: null,
			},
			categoriaSelect: [],
			timeID: null,
			filtering: false,
			loadingData: true,
			filter: false,
			withoutColab: false,
			today: moment().format("YYYY-MM-DD"),
			table: {
				fields: [
					{ key: "nome", label: "NOME", sortable: true, class: "mw-300" },
					{ key: "cpf", label: "CPF", sortable: true, class: "mw-150" },
          
					{
						key: "categoria",
						label: "CATEGORIA",
						sortable: true,
						class: "mw-150",
					},
					{
						key: "matricula",
						label: "MATRÍCULA",
						sortable: false,
						class: "mw-50",
					},
					{ key: "setor", label: "SETOR", sortable: false, class: "mw-50" },
					{
						key: "actions",
						label: "AÇÕES",
						sortable: false,
						class: "mw-150 text-center col-1",
					},
				],
				items: [],
				optionsSize: [10, 25, 50, 100],
				columnOrder: "nome",
				order: "asc",
			},
			eliminateItem: "Convênio",
			paginationData: {
				currentPage: 0,
				totalLines: 0,
				fromLine: 0,
				toLine: 0,
				defaultSize: 10,
			},
			canManageColab: false,
			empresa: {
				id_empresa: "",
				nome: "",
        		gerenciarColaboradores: false,
			},
			cadastroCompleto: true,
			situacaoCadastro: 'Completo',
		};
	},

  mounted() {
    if (
      (getIdEmpresa() === null || typeof getIdEmpresa() == "undefined") &&
      typeof this.$route.params.idEmpresa == "undefined"
    ) {
      this.openModalError();
      return;
    }

    if (typeof this.$route.params.idEmpresa != "undefined") {
      if (this.$route.params.idEmpresa !== getIdEmpresa()) {
        setIdEmpresa(this.$route.params.idEmpresa);
      }
    }

    this.$http.get(this.$api.getMessageTermCompany(getIdEmpresa())).then(({ data }) => {
      this.termsInfo.message = VerificaAceiteTermoEmpresa.mensagemExibicaoTermoEmpresa(data);
		}).catch(() => { this.cadastroCompleto = false; });

		this.$http.get(this.$api.getShowAndEditEmpresa(getIdEmpresa())).then(({ data }) => {
			this.situacaoCadastro = data['situacao_cadastro'];
			this.empresa.id_empresa = data.id_empresa;
			this.empresa.nome = data['cnpj'] ? data['nome_fantasia'] : data['nome'];
			this.empresa.gerenciarColaboradores = data['pode_gerenciar_colaboradores'];
			this.linkItems[2].name = this.empresa.nome;
      this.loadFilters();
			this.loadDataColaborador(null, true);
		});

    this.canManageColab = this.$can(
      actions.GERENCIAR,
      subjects.COLABORADOR_MENUS_ROTAS
    );
  },

  methods: {
    setParams() {
      return {
        tamanho: this.paginationData.defaultSize,
        pagina: this.paginationData.currentPage,
        colunaOrdenar: this.table.columnOrder,
        ordem: this.table.order,
        id_empresa: this.empresa.id_empresa,
        ano: moment().format("YYYY"),
        ativo: true,
        nome: this.search.nome,
        cpf: this.search.cpf,
        id_categoria: this.search.categoria ? this.search.categoria.id_categoria : null,
        matricula: this.search.matricula,
        setor: this.search.setor,
      };
    },

    loadDataColaborador(filterData = null, first = false) {
      this.paginationData.defaultSize = filterData
        ? filterData.defaultSize
        : 10;

      if (filterData?.currentPage) {
        this.paginationData.currentPage = filterData.currentPage;
      }

      this.filtering = true;

      this.$http.get(this.$api.colaborador(), {
        params: this.setParams()
      }).then(({ data }) => {
        this.table.items = data.data;

        if (first) {
          this.loadingData = false;
        }

        this.withoutColab = data.data == 0;

        this.filtering = false;
        this.startPagination(data);
      });
    },

    startPagination(data) {
      this.paginationData.currentPage = data.meta.current_page;
      this.paginationData.totalLines = data.meta.total;
      this.paginationData.fromLine = data.meta.from;
      this.paginationData.toLine = data.meta.to;
    },

    ordenarColuna(sort) {
      this.table.columnOrder = sort.sortBy;
      this.table.order = sort.sortDesc ? "desc" : "asc";

      this.filtering = true;
      this.filter = !this.filter;

      this.loadDataColaborador();
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page;
      this.filtering = true;
      this.filter = !this.filter;
      this.loadDataColaborador();
    },

    openModalError() {
      this.modalError.showModal = true;
    },

    redirectToList() {
      this.$router.push({ name: "empresa-show" });
    },

    loadFilters(){
      this.$http.get(this.$api.getEmpresaCategoria()).then(({ data }) => {
        this.categoriaSelect = data.filter(
          (item) => item.descricao !== this.eliminateItem
        );
      });
    },

    redirectToShow({
      id_pessoa_fisica,
      cpf,
      nome,
      genero,
      data_nascimento,
      id_categoria,
      categoria,
      ano,
      editavel,
      setor,
      matricula,
      nome_mae,
      id_nacionalidade,
      descricao_nacionalidade,
    }) {
      this.$router.push({
        name: "empresa-colaborador-show",
        params: {
          idPessoa: id_pessoa_fisica,
          cpf: cpf,
          nome: nome,
          genero: genero,
          data_nascimento: data_nascimento,
          nome_mae: nome_mae,
          id_nacionalidade: id_nacionalidade,
          descricao_nacionalidade: descricao_nacionalidade,
          categoria: {
            id_categoria: id_categoria,
            descricao: categoria,
          },
          empresa: this.empresa,
          ano: ano,
          editavel: editavel,
          setor: setor,
          matricula: matricula,
        },
      });
    },

    redirectToEdit({
      id_pessoa_fisica,
      cpf,
      nome,
      genero,
      data_nascimento,
      id_categoria,
      categoria,
      ano,
      editavel,
      setor,
      matricula,
      nome_mae,
      id_nacionalidade,
      descricao_nacionalidade,
    }) {
      this.$router.push({
        name: "empresa-colaborador-edit",
        params: {
          idPessoa: id_pessoa_fisica,
          cpf: cpf,
          nome: nome,
          genero: genero,
          data_nascimento: data_nascimento,
          nome_mae: nome_mae,
          id_nacionalidade: id_nacionalidade,
          descricao_nacionalidade: descricao_nacionalidade,
          categoria: {
            id_categoria: id_categoria,
            descricao: categoria,
          },
          empresa: this.empresa,
          ano: ano,
          editavel: editavel,
          setor: setor,
          matricula: matricula,
        },
      });
    },

    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
    },

    resolveRoute(route) {
      if (route != this.$route.name) {
        this.$router.push({
          name: route,
          params: {
            empresa: this.empresa,
          },
        });
      }
    },

    handleSubmitFormFilters() {
      this.paginationData.currentPage = 1
      this.$refs.formFilters.validate().then(success => {
        if (success) {
            this.loadDataColaborador()
        }
      })
    },

    clearFilters() {
      this.paginationData.currentPage = 1
      this.search.nome = null
      this.search.cpf = null
      this.search.categoria = null
      this.search.matricula = null
      this.search.setor = null

      this.loadDataColaborador()
    },

    trataDescricaoMatriculaSetor(descricao) {
      return descricao != null ? descricao : "-";
    },
  },
};
</script>

<style scoped>
.header {
  z-index: 2;
}
.custom {
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  font-weight: 400;
}
.altura_max_scrollbar {
  max-height: 100px;
  padding-right: 15px;
}
.comprimento_maximo_celulas {
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.hidden_overflow_tabela {
  overflow: hidden;
}
.termo{
  font-size: 14px;
}
</style>